import React, { useState } from 'react';
import { auth, db } from '../../services/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleRegister = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Add user to Firestore
            await setDoc(doc(db, 'users', user.uid), {
                email: user.email,
                createdAt: serverTimestamp(),
                // Add other user fields as needed
            });

            navigate('/');
        } catch (error) {
            console.error('Error registering user:', error);
            setError(error.message);
        }
    };

    return (
        <form onSubmit={handleRegister} style={formStyles}>
            <h2>Register</h2>
            {error && <p style={errorStyles}>{error}</p>}
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
                style={inputStyles}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
                style={inputStyles}
            />
            <button type="submit" style={buttonStyles}>Register</button>
            <p style={disclaimerStyles}>
                By registering, you agree that your email and all personal information will be sold to the highest bidder... Arghhh.
            </p>
            <p style={redirectStyles}>
                Already have an account? <a href="/login">Login here</a>
            </p>
        </form>
    );
};

const formStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '50px',
};

const inputStyles = {
    padding: '10px',
    margin: '10px',
    width: '300px',
    fontSize: '16px',
};

const buttonStyles = {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
};

const errorStyles = {
    color: 'red',
    marginBottom: '10px',
};

const disclaimerStyles = {
    marginTop: '20px',
    fontSize: '14px',
    color: 'gray',
    maxWidth: '400px',
    textAlign: 'center',
};

const redirectStyles = {
    marginTop: '10px',
};

export default Register;


