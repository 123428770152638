import React from 'react';
import './HelpModal.css';

const HelpModal = ({ closeModal }) => {
    return (
        <div className="modal-overlay">
            <div className="help-modal-content">
                <button className="close-button" onClick={closeModal}>
                    &times;
                </button>
                <h2>How to Use mapChatter</h2>
                <div className="help-content">
                    <h3>Adding a Location</h3>
                    <p>
                        To add a new location to the map, simply click anywhere on the map where you
                        want to place the location. A form will appear allowing you to enter details
                        about the location.
                    </p>

                    <h3>Viewing Location Details</h3>
                    <p>
                        Click on any marker on the map to view details about that location, including
                        its description and any images associated with it.
                    </p>

                    <h3>Searching for Locations</h3>
                    <p>
                        Use the search bar at the top-left corner to search for locations by name or
                        description.
                    </p>

                    <h3>Filtering by Groups</h3>
                    <p>
                        Use the group filter dropdown to filter locations displayed on the map by group.
                    </p>

                    <h3>Chatting</h3>
                    <p>
                        Click on the "Chat Feed" button in the navigation bar to open the chat feed.
                        Here, you can view and participate in conversations related to visible locations.
                    </p>

                    <h3>Managing Groups</h3>
                    <p>
                        Click on the "Groups" button in the navigation bar to manage your groups. You
                        can create new groups, add or remove members, and manage locations within groups.
                    </p>

                    <h3>User Authentication</h3>
                    <p>
                        You need to be logged in to add locations, participate in chats, and manage
                        groups. Use the "Logout" button to sign out of your account.
                    </p>

                    <h3>Need More Help?</h3>
                    <p>
                        If you have any questions or need further assistance, please contact our support
                        team at <a href="mailto:support@leaflane.com">support@leaflane.com</a>.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default HelpModal;