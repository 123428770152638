import React, { useState } from 'react';
import { auth } from '../../services/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/'); // Redirect to dashboard on successful login
        } catch (error) {
            console.error('Login Error:', error);
            setError(error.message);
        }
    };

    return (
        <form onSubmit={handleLogin} style={formStyles}>
            <h2>Login</h2>
            {error && <p style={errorStyles}>{error}</p>}
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={inputStyles}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={inputStyles}
            />
            <button type="submit" style={buttonStyles}>Login</button>
            <p style={redirectStyles}>
                Don't have an account? <a href="/register">Register here</a>
            </p>
        </form>
    );
};

const formStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '50px',
};

const inputStyles = {
    padding: '10px',
    margin: '10px',
    width: '300px',
    fontSize: '16px',
};

const buttonStyles = {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
};

const errorStyles = {
    color: 'red',
    marginBottom: '10px',
};

const redirectStyles = {
    marginTop: '10px',
};

export default Login;