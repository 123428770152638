import React, { useState, useRef, useEffect, useCallback } from 'react';
import { db, auth, storage } from 'services/firebase';
import {
    collection,
    addDoc,
    query,
    orderBy,
    onSnapshot,
    serverTimestamp,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './Chat.css';
import Modal from 'react-modal';
import { FaPaperPlane, FaImage } from 'react-icons/fa';

Modal.setAppElement('#root'); // For accessibility

const Chat = ({ location, onLocationClick }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [replyMessageMap, setReplyMessageMap] = useState({});
    const [replyingToId, setReplyingToId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUpload, setImageUpload] = useState(null);
    const fileInputRef = useRef();
    const currentUser = auth.currentUser;
    const messagesEndRef = useRef(null);

    useEffect(() => {
        return () => {
            setSelectedImage(null);
        };
    }, []);

    const handleViewOnMap = () => {
        if (onLocationClick) {
            onLocationClick(location);
        }
    };

    useEffect(() => {
        const messagesRef = collection(db, 'locations', location.id, 'messages');
        const q = query(messagesRef, orderBy('timestamp', 'desc'));

        const unsubscribe = onSnapshot(q, snapshot => {
            const fetchedMessages = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setMessages(fetchedMessages);
        });

        return () => unsubscribe();
    }, [location.id]);



    const sendMessage = async (e) => {
        e.preventDefault();
        if (!input.trim() && !imageUpload) return;

        try {
            let imageUrl = null;

            if (imageUpload) {
                const imageRef = ref(storage, `images/${location.id}/${Date.now()}_${imageUpload.name}`);
                await uploadBytes(imageRef, imageUpload);
                imageUrl = await getDownloadURL(imageRef);
            }

            await addDoc(collection(db, 'locations', location.id, 'messages'), {
                text: input.trim(),
                user: currentUser ? currentUser.email : 'Anonymous',
                timestamp: serverTimestamp(),
                imageUrl,
            });
            setInput('');
            setImageUpload(null);
        } catch (error) {
            console.error('Chat: Error sending message:', error);
            alert('Failed to send message.');
        }
    };

    const handleReply = useCallback(
        async (parentId) => {
            const replyText = replyMessageMap[parentId]?.trim();
            if (!replyText) return;

            try {
                await addDoc(collection(db, 'locations', location.id, 'messages'), {
                    text: replyText,
                    user: currentUser ? currentUser.email : 'Anonymous',
                    timestamp: serverTimestamp(),
                    parentId,
                });
                setReplyMessageMap(prev => ({ ...prev, [parentId]: '' }));
                setReplyingToId(null);
            } catch (error) {
                console.error('Chat: Error sending reply:', error);
                alert('Failed to send reply.');
            }
        },
        [location.id, replyMessageMap, currentUser]
    );

    const renderReplies = useCallback((parentId) => {
        const replies = messages.filter(msg => msg.parentId === parentId);
        if (replies.length === 0) return null;

        return (
            <div className="replies">
                {replies.map(reply => (
                    <div key={reply.id} className="reply">
                        <strong>{getDisplayName(reply.user)}</strong>: {reply.text}
                    </div>
                ))}
            </div>
        );
    }, [messages]);

    const handleImageClick = useCallback((imageUrl) => {
        setSelectedImage(imageUrl);
    }, []);

    const closeImageModal = useCallback(() => {
        setSelectedImage(null);
    }, []);



    const getDisplayName = (email) => {
        return email.split('@')[0];
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageUpload(file);
        }
    };

    return (
        <div className="chat-container">
            <div className="messages-container">
                <div className="chat-location" onClick={() => onLocationClick(location)}>
                    <span className="location-name">{location.name}</span>
                    <span className="location-action" onClick={handleViewOnMap}>View on map</span>
                </div>
                {messages
                    .filter(msg => !msg.parentId)
                    .sort((a, b) => (b.timestamp?.seconds || 0) - (a.timestamp?.seconds || 0))
                    .map(message => (
                        <div key={message.id} className="chat-item">
                            <div className="chat-header">
                                <div className="chat-user-info">
                                    <strong>{getDisplayName(message.user)}</strong>
                                    <span className="chat-timestamp">
                                        {message.timestamp?.toDate().toLocaleString()}
                                    </span>
                                </div>
                            </div>
                            <div className="chat-content">
                                <p>{message.text}</p>
                                {message.imageUrl && (
                                    <img
                                        src={message.imageUrl}
                                        alt="Message Attachment"
                                        className="chat-image"
                                        onClick={() => handleImageClick(message.imageUrl)}
                                    />
                                )}
                            </div>
                            <button onClick={() => setReplyingToId(message.id)} className="reply-button">
                                Reply
                            </button>
                            {replyingToId === message.id && (
                                <form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        handleReply(message.id);
                                    }}
                                    className="reply-form"
                                >
                                    <input
                                        type="text"
                                        value={replyMessageMap[message.id] || ''}
                                        onChange={e =>
                                            setReplyMessageMap(prev => ({
                                                ...prev,
                                                [message.id]: e.target.value,
                                            }))
                                        }
                                        placeholder="Type a reply..."
                                        className="reply-input"
                                    />
                                    <button type="submit" className="send-reply-button">
                                        Send
                                    </button>
                                </form>
                            )}
                            {renderReplies(message.id)}
                        </div>
                    ))}
                <div ref={messagesEndRef}></div>
            </div>
            {selectedImage && (
                <Modal
                    isOpen={!!selectedImage}
                    onRequestClose={closeImageModal}
                    contentLabel="Image Preview"
                    className="image-modal"
                    overlayClassName="image-overlay"
                >
                    <img src={selectedImage} alt="Full View" className="full-image" />
                    <button onClick={closeImageModal} className="close-modal-button">
                        &times;
                    </button>
                </Modal>
            )}
            <form onSubmit={sendMessage} className="chat-form">
                <div className="chat-input-wrapper">
                    <input
                        type="text"
                        value={input}
                        onChange={e => setInput(e.target.value)}
                        placeholder="Type your message..."
                        className="chat-input"
                    />
                    <div className="chat-buttons">
                        <label htmlFor="imageUpload" className="image-upload-button">
                            <FaImage />
                            <input
                                type="file"
                                id="imageUpload"
                                ref={fileInputRef}
                                accept="image/*"
                                onChange={handleImageUpload}
                                style={{ display: 'none' }}
                            />
                        </label>
                        <button type="submit" className="send-button">
                            <FaPaperPlane />
                        </button>
                    </div>
                </div>
                {imageUpload && (
                    <div className="image-preview">
                        <p>Image selected: {imageUpload.name}</p>
                        <button onClick={() => setImageUpload(null)} className="remove-image-button">
                            Remove
                        </button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default Chat;