import React, { useState } from 'react';
import CreateGroupModal from './CreateGroupModal';
import EditGroupModal from './EditGroupModal';
import './GroupModal.css';

const GroupModal = ({ isOpen, groups = [], closeModal, refreshGroups }) => {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [editingGroup, setEditingGroup] = useState(null);

    const openCreateModal = () => {
        setIsCreateModalOpen(true);
    };

    const closeCreateModal = () => {
        setIsCreateModalOpen(false);
    };

    const openEditModal = (group) => {
        setEditingGroup(group);
    };

    const closeEditModal = () => {
        setEditingGroup(null);
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="close-button" onClick={closeModal}>&times;</span>
                <h2>Your Groups</h2>
                <ul>
                    {groups.map(group => (
                        <li key={group.id}>
                            <span className="group-name">{group.name}</span>
                            <button onClick={() => openEditModal(group)} className="edit-button">Edit</button>
                        </li>
                    ))}
                </ul>
                <button onClick={openCreateModal} className="create-group-button">
                    Create New Group
                </button>

                {/* Create Group Modal */}
                {isCreateModalOpen && (
                    <CreateGroupModal closeModal={closeCreateModal} refreshGroups={refreshGroups} />
                )}

                {/* Edit Group Modal */}
                {editingGroup && (
                    <EditGroupModal group={editingGroup} closeModal={closeEditModal} />
                )}
            </div>
        </div>
    );
};

export default GroupModal;