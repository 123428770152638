// src/components/Groups/EditGroupModal.jsx
import React, { useState, useEffect } from 'react';
import { db, auth } from 'services/firebase';
import {
    doc,
    updateDoc,
    deleteDoc,
    getDoc,
    collection,
    query,
    where,
    getDocs,
    addDoc
} from 'firebase/firestore';
import './EditGroupModal.css';

// Add this helper function at the top of your file, outside of the component
const maskEmail = (email) => {
    const [username, domain] = email.split('@');
    const [domainName, topLevelDomain] = domain.split('.');
    const maskedDomainName = domainName.slice(0, 4).replace(/./g, '*') + domainName.slice(4);
    return `${username}@${maskedDomainName}.${topLevelDomain}`;
};

const EditGroupModal = ({ group, closeModal }) => {
    const [name, setName] = useState(group.name);
    const [description, setDescription] = useState(group.description);
    const [isPublic, setIsPublic] = useState(group.isPublic);
    const [members, setMembers] = useState([]);
    const [locations, setLocations] = useState([]);
    const [newMemberEmail, setNewMemberEmail] = useState('');
    const [allUsers, setAllUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [newLocationName, setNewLocationName] = useState('');
    const [filteredLocations, setFilteredLocations] = useState([]);
    const currentUser = auth.currentUser;

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const memberPromises = group.members.map(async (userId) => {
                    const userRef = doc(db, 'users', userId);
                    const userSnap = await getDoc(userRef);
                    if (userSnap.exists()) {
                        const userData = userSnap.data();
                        return { id: userId, email: userData.email || 'No Email Available' };
                    } else {
                        return { id: userId, email: 'Unknown User' };
                    }
                });
                const membersData = await Promise.all(memberPromises);
                setMembers(membersData);
            } catch (error) {
                console.error('Error fetching members:', error);
            }
        };

        const fetchAllUsers = async () => {
            try {
                const usersRef = collection(db, 'users');
                const snapshot = await getDocs(usersRef);
                const usersData = snapshot.docs.map(doc => ({ id: doc.id, email: doc.data().email }));
                setAllUsers(usersData);
            } catch (error) {
                console.error('Error fetching all users:', error);
            }
        };

        const fetchLocations = async () => {
            try {
                const locationsRef = collection(db, 'locations');
                const locQuery = query(locationsRef, where('groupId', '==', group.id));
                const snapshot = await getDocs(locQuery);
                const locationsData = snapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
                setLocations(locationsData);
            } catch (error) {
                console.error('Error fetching locations:', error);
            }
        };

        fetchMembers();
        fetchAllUsers();
        fetchLocations();
    }, [group.members, group.id]);

    useEffect(() => {
        if (newMemberEmail) {
            const filtered = allUsers.filter(user =>
                user.email.toLowerCase().includes(newMemberEmail.toLowerCase()) &&
                !group.members.includes(user.id)
            );
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers([]);
        }
    }, [newMemberEmail, allUsers, group.members]);

    useEffect(() => {
        if (newLocationName) {
            const filtered = locations.filter(location =>
                location.name.toLowerCase().includes(newLocationName.toLowerCase()) &&
                !group.locations.includes(location.id)
            );
            setFilteredLocations(filtered);
        } else {
            setFilteredLocations([]);
        }
    }, [newLocationName, locations, group.locations]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name.trim()) {
            alert('Group name is required.');
            return;
        }

        try {
            const groupRef = doc(db, 'groups', group.id);
            await updateDoc(groupRef, {
                name: name.trim(),
                description: description.trim(),
                isPublic: isPublic,
            });
            closeModal();
        } catch (error) {
            console.error('Error updating group:', error);
            alert('Failed to update group.');
        }
    };

    const handleDeleteUser = async (userId) => {
        if (!currentUser || currentUser.uid !== group.createdBy) {
            alert('Only the group creator can remove members.');
            return;
        }

        if (userId === currentUser.uid) {
            alert('You cannot remove yourself from the group.');
            return;
        }

        if (window.confirm('Are you sure you want to remove this member from the group?')) {
            try {
                const groupRef = doc(db, 'groups', group.id);
                const updatedMembers = group.members.filter(id => id !== userId);
                await updateDoc(groupRef, { members: updatedMembers });
                setMembers(members.filter(member => member.id !== userId));
            } catch (error) {
                console.error('Error removing member:', error);
                alert('Failed to remove member.');
            }
        }
    };

    const handleAddMember = async (userId) => {
        try {
            const groupRef = doc(db, 'groups', group.id);
            const updatedMembers = [...group.members, userId];
            await updateDoc(groupRef, { members: updatedMembers });
            const addedUser = allUsers.find(user => user.id === userId);
            setMembers([...members, addedUser]);
            setNewMemberEmail('');
            setFilteredUsers([]);
        } catch (error) {
            console.error('Error adding member:', error);
            alert('Failed to add member.');
        }
    };

    const handleRemoveLocationFromGroup = async (locationId) => {
        if (!currentUser || currentUser.uid !== group.createdBy) {
            alert('Only the group creator can remove locations from the group.');
            return;
        }

        try {
            // Fetch Public Group
            const publicGroupQuery = query(collection(db, 'groups'), where('isPublic', '==', true));
            const snapshot = await getDocs(publicGroupQuery);
            if (snapshot.empty) {
                alert('No public group found. Please create a public group first.');
                return;
            }
            const publicGroup = snapshot.docs[0];
            const publicGroupId = publicGroup.id;

            // Update Location's groupId to Public Group
            const locationRef = doc(db, 'locations', locationId);
            await updateDoc(locationRef, { groupId: publicGroupId });

            // Update local state
            setLocations(locations.filter(loc => loc.id !== locationId));

            alert('Location has been moved to the Public group.');
        } catch (error) {
            console.error('Error removing location from group:', error);
            alert('Failed to remove location from group.');
        }
    };

    const handleDeleteLocation = async (locationId) => {
        if (!currentUser || currentUser.uid !== group.createdBy) {
            alert('Only the group creator can delete locations.');
            return;
        }

        if (window.confirm('Are you sure you want to delete this location? This action cannot be undone.')) {
            try {
                const locationRef = doc(db, 'locations', locationId);
                await deleteDoc(locationRef);
                setLocations(locations.filter(loc => loc.id !== locationId));
                alert('Location has been deleted.');
            } catch (error) {
                console.error('Error deleting location:', error);
                alert('Failed to delete location.');
            }
        }
    };

    const handleAddLocation = async (locationId) => {
        try {
            const locationRef = doc(db, 'locations', locationId);
            await updateDoc(locationRef, { groupId: group.id });

            const addedLocation = locations.find(loc => loc.id === locationId);
            setLocations([...locations, addedLocation]);
            setNewLocationName('');
            setFilteredLocations([]);
        } catch (error) {
            console.error('Error adding location:', error);
            alert('Failed to add location.');
        }
    };

    // New handleDeleteGroup function
    const handleDeleteGroup = async () => {
        if (!currentUser || currentUser.uid !== group.createdBy) {
            alert('Only the group creator can delete the group.');
            return;
        }

        const confirmation = window.confirm('Are you sure you want to delete this group? This action cannot be undone.');
        if (!confirmation) return;

        try {
            console.log('Initiating group deletion:', group.id);

            // Reassign associated locations to a public group
            const locationsRef = collection(db, 'locations');
            const locQuery = query(locationsRef, where('groupId', '==', group.id));
            const snapshot = await getDocs(locQuery);
            console.log(`Found ${snapshot.size} locations associated with the group.`);

            if (!snapshot.empty) {
                console.log('Searching for existing public groups.');
                const publicGroupQuery = query(collection(db, 'groups'), where('isPublic', '==', true));
                const publicSnapshot = await getDocs(publicGroupQuery);

                let publicGroupId;
                if (!publicSnapshot.empty) {
                    publicGroupId = publicSnapshot.docs[0].id;
                    console.log(`Using existing public group with ID: ${publicGroupId}`);
                } else {
                    console.log('No public group found. Creating a new public group.');
                    const newPublicGroup = await addDoc(collection(db, 'groups'), {
                        name: 'Public Group',
                        description: 'Default public group',
                        isPublic: true,
                        createdBy: currentUser.uid,
                        members: [currentUser.uid],
                    });
                    publicGroupId = newPublicGroup.id;
                    console.log(`Created new public group with ID: ${publicGroupId}`);
                }

                // Update each location's groupId to the public group
                const updatePromises = snapshot.docs.map(doc =>
                    updateDoc(doc.ref, { groupId: publicGroupId })
                );
                await Promise.all(updatePromises);
                console.log('All associated locations have been reassigned to the public group.');
            } else {
                console.log('No locations to reassign.');
            }

            // Delete the group document
            const groupRef = doc(db, 'groups', group.id);
            await deleteDoc(groupRef);
            console.log('Group document deleted:', group.id);

            alert('Group has been deleted successfully.');
            closeModal();
        } catch (error) {
            console.error('Error deleting group:', error);
            alert(`Failed to delete the group: ${error.message}`);
        }
    };

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="close-button" onClick={closeModal}>&times;</span>
                <h2>Edit Group</h2>
                <form onSubmit={handleSubmit} className="edit-group-form">
                    <div className="form-group">
                        <label htmlFor="name">Group Name:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            required
                            className="form-input"
                            placeholder="Enter group name"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description:</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            className="form-textarea"
                            placeholder="Enter group description"
                        />
                    </div>
                    <div className="form-group">
                        <label className="public-group-label">
                            <input
                                type="checkbox"
                                id="isPublic"
                                checked={isPublic}
                                onChange={e => setIsPublic(e.target.checked)}
                            />
                            Public Group
                        </label>
                    </div>
                    <div className="button-group">
                        <button type="submit" className="action-button">Update Group</button>
                        <button onClick={handleDeleteGroup} className="action-button delete-button">
                            Delete Group
                        </button>
                    </div>
                </form>

                <div className="group-details">
                    <h3>Members</h3>
                    <ul>
                        {members.length > 0 ? (
                            members.map(member => (
                                <li key={member.id}>
                                    {member.email}
                                    {currentUser && currentUser.uid === group.createdBy && member.id !== currentUser.uid && (
                                        <button onClick={() => handleDeleteUser(member.id)} className="action-button delete-button">Remove</button>
                                    )}
                                </li>
                            ))
                        ) : (
                            <li>No members found.</li>
                        )}
                    </ul>
                </div>

                {currentUser && currentUser.uid === group.createdBy && (
                    <div className="add-member-section">
                        <h4>Add Member</h4>
                        <input
                            type="text"
                            value={newMemberEmail}
                            onChange={e => setNewMemberEmail(e.target.value)}
                            placeholder="Type member email"
                            className="member-input"
                        />
                        {filteredUsers.length > 0 && (
                            <ul className="autocomplete-list">
                                {filteredUsers.map(user => (
                                    <li key={user.id} onClick={() => handleAddMember(user.id)}>
                                        {maskEmail(user.email)}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}

                <div className="group-details">
                    <h3>Locations</h3>
                    <ul>
                        {locations.length > 0 ? (
                            locations.map(location => (
                                <li key={location.id} className="location-item">
                                    {location.name}
                                    {currentUser && currentUser.uid === group.createdBy && (
                                        <div className="location-actions">
                                            <button onClick={() => handleRemoveLocationFromGroup(location.id)} className="action-button">Remove</button>
                                            <button onClick={() => handleDeleteLocation(location.id)} className="action-button delete-button">Delete</button>
                                        </div>
                                    )}
                                </li>
                            ))
                        ) : (
                            <li>No locations assigned to this group.</li>
                        )}
                    </ul>
                </div>

                {currentUser && currentUser.uid === group.createdBy && (
                    <div className="add-location-section">
                        <h4>Add Location</h4>
                        <input
                            type="text"
                            value={newLocationName}
                            onChange={e => setNewLocationName(e.target.value)}
                            placeholder="Type location name"
                            className="location-input"
                        />
                        {filteredLocations.length > 0 && (
                            <ul className="autocomplete-list">
                                {filteredLocations.map(loc => (
                                    <li key={loc.id} onClick={() => handleAddLocation(loc.id)}>
                                        {loc.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditGroupModal;