import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
    return (
        <div className="landing-page">
            <div className="content">
                <h1>Welcome to mapChatter</h1>
                <p>
                    mapChatter is an interactive platform that allows you to explore and share locations with friends and groups. Connect over shared places, leave messages on the map, and discover new experiences recommended by your network. Join us today and start your journey!
                </p>
                <div className="buttons">
                    <Link to="/register" className="register-button">Register Now</Link>
                    <Link to="/login" className="login-button">Login</Link>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;