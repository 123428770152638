import React, { useState, useEffect } from 'react';
import { db, auth } from 'services/firebase';
import { collection, addDoc, getDocs, query, serverTimestamp, where as whereFirestore, or } from 'firebase/firestore';
import './AddLocationModal.css';
import { doc, getDoc } from 'firebase/firestore';
const AddLocationModal = ({ closeModal, latlng }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
    const [newGroupName, setNewGroupName] = useState('');
    const [newGroupDescription, setNewGroupDescription] = useState('');
    const currentUser = auth.currentUser;

    useEffect(() => {
        const fetchGroups = async () => {
            if (!currentUser) {
                console.warn('User not authenticated.');
                return;
            }

            try {
                const groupsRef = collection(db, 'groups');
                const q = query(
                    groupsRef,
                    or(
                        whereFirestore('members', 'array-contains', currentUser.uid),
                        whereFirestore('isPublic', '==', true)
                    )
                );
                const snapshot = await getDocs(q);
                const groupsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                console.log('Fetched Groups:', groupsList); // Debugging log
                setGroups(groupsList);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchGroups();
    }, [currentUser]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !description || !selectedGroup) {
            alert('All fields are required.');
            return;
        }

        if (!currentUser) {
            alert('User not authenticated.');
            return;
        }

        // Debugging Logs
        console.log('Current User UID:', currentUser.uid);
        console.log('Selected Group ID:', selectedGroup);

        // Optional: Verify Group Details
        try {
            const groupRef = doc(db, 'groups', selectedGroup);
            const groupSnap = await getDoc(groupRef);
            if (groupSnap.exists()) {
                const groupData = groupSnap.data();
                console.log('Group Data:', groupData);
                if (!groupData.isPublic && !groupData.members.includes(currentUser.uid)) {
                    alert('You do not have permission to add a location to this group.');
                    return;
                }
            } else {
                alert('Selected group does not exist.');
                return;
            }
        } catch (error) {
            console.error('Error fetching group data:', error);
            alert('Failed to verify group permissions.');
            return;
        }

        console.log('Attempting to add location with the following data:', {
            name: name.trim(),
            description: description.trim(),
            latitude: latlng.lat,
            longitude: latlng.lng,
            groupId: selectedGroup,
            createdBy: currentUser.uid,
        });

        try {
            await addDoc(collection(db, 'locations'), {
                name: name.trim(),
                description: description.trim(),
                latitude: latlng.lat,
                longitude: latlng.lng,
                groupId: selectedGroup,
                createdAt: serverTimestamp(),
                createdBy: currentUser.uid,
            });
            //alert('Location added successfully!');
            closeModal();
        } catch (error) {
            console.error('Error adding location:', error);
            alert('Failed to add location. Please try again.');
        }
    };

    const handleCreateGroup = async (e) => {
        e.preventDefault();

        if (!newGroupName) {
            alert('Group name is required.');
            return;
        }

        if (!currentUser) {
            alert('User not authenticated.');
            return;
        }

        try {
            const groupsRef = collection(db, 'groups');
            const newGroup = {
                name: newGroupName.trim(),
                description: newGroupDescription.trim(),
                createdAt: serverTimestamp(),
                createdBy: currentUser.uid,
                members: [currentUser.uid], // Add the creator as a member
                isPublic: false, // Set based on your logic
                locations: [],
            };

            // Create the new group
            const docRef = await addDoc(groupsRef, newGroup);
            console.log('Group created with ID:', docRef.id);
            alert('Group created successfully!');
            setIsCreateGroupModalOpen(false);

            // Fetch the updated groups list
            try {
                const q = query(
                    groupsRef,
                    or(
                        whereFirestore('members', 'array-contains', currentUser.uid),
                        whereFirestore('isPublic', '==', true)
                    )
                );
                const snapshot = await getDocs(q);
                const groupsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setGroups(groupsList);
                console.log('Updated Groups List:', groupsList); // Debugging log
            } catch (fetchError) {
                console.error('Error fetching updated groups:', fetchError);
                alert('Group was created, but failed to fetch updated groups.');
            }
        } catch (error) {
            console.error('Error creating group:', error);
            alert('Failed to create group.');
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <span className="close-button" onClick={closeModal}>&times;</span>
                <h2>Add New Location</h2>
                <form onSubmit={handleSubmit} className="add-location-form">
                    <div className="form-group">
                        <label htmlFor="name">Location Name:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            required
                            placeholder="Enter location name"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description:</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            required
                            placeholder="Enter description"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="group">Select Group:</label>
                        <select
                            id="group"
                            value={selectedGroup}
                            onChange={e => setSelectedGroup(e.target.value)}
                            required
                        >
                            <option value="">--Select Group--</option>
                            {groups.map(group => (
                                <option key={group.id} value={group.id}>{group.name}</option>
                            ))}
                        </select>
                        <button
                            type="button"
                            onClick={() => setIsCreateGroupModalOpen(true)}
                            className="create-group-button"
                        >
                            Create New Group
                        </button>
                    </div>
                    <button type="submit" className="submit-button">Add Location</button>
                </form>
            </div>

            {/* Create Group Modal */}
            {isCreateGroupModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close-button" onClick={() => setIsCreateGroupModalOpen(false)}>&times;</span>
                        <h2>Create New Group</h2>
                        <form onSubmit={handleCreateGroup} className="create-group-form">
                            <div className="form-group">
                                <label htmlFor="newGroupName">Group Name:</label>
                                <input
                                    type="text"
                                    id="newGroupName"
                                    value={newGroupName}
                                    onChange={e => setNewGroupName(e.target.value)}
                                    required
                                    placeholder="Enter group name"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="newGroupDescription">Description:</label>
                                <textarea
                                    id="newGroupDescription"
                                    value={newGroupDescription}
                                    onChange={e => setNewGroupDescription(e.target.value)}
                                    placeholder="Enter group description"
                                />
                            </div>
                            <button type="submit" className="submit-button">Create Group</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddLocationModal;