import React, { useState } from 'react';
import { db, auth } from 'services/firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import './CreateGroupModal.css';

const CreateGroupModal = ({ closeModal, refreshGroups }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isPublic, setIsPublic] = useState(false);
    const currentUser = auth.currentUser;

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name.trim()) {
            alert('Group name is required.');
            return;
        }

        try {
            const groupsRef = collection(db, 'groups');
            await addDoc(groupsRef, {
                name: name.trim(),
                description: description.trim(),
                createdBy: currentUser.uid,
                createdAt: serverTimestamp(),
                members: isPublic ? [] : [currentUser.uid],
                locations: [],
                isPublic: isPublic,
            });

            //alert('Group created successfully!');

            refreshGroups();
            closeModal();

        } catch (error) {
            console.error('Error creating group:', error);
            alert('Failed to create group.');
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <span className="close-button" onClick={closeModal}>&times;</span>
                <h2>Create New Group</h2>
                <form onSubmit={handleSubmit} className="create-group-form">
                    <div className="form-group">
                        <label htmlFor="name">Group Name:</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            required
                            className="form-input"
                            placeholder="Enter group name"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description:</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            className="form-textarea"
                            placeholder="Enter group description"
                        />
                    </div>
                    <div className="public-group-container">
                        <label htmlFor="isPublic" className="public-group-label">
                            Public
                        </label>
                        <input
                            type="checkbox"
                            id="isPublic"
                            checked={isPublic}
                            onChange={e => setIsPublic(e.target.checked)}
                            className="public-group-checkbox"
                        />
                    </div>
                    <button type="submit" className="submit-button">Create Group</button>
                </form>
            </div>
        </div>
    );
};

export default CreateGroupModal;