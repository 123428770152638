import React, { useState, useCallback, useEffect } from 'react';
import { signOut } from 'firebase/auth';
import { auth, db } from 'services/firebase';
import { useNavigate } from 'react-router-dom';
import MapView from '../components/Map/MapView';
import GroupModal from '../components/Groups/GroupModal';
import ChatFeedModal from '../components/ChatFeed/ChatFeedModal';
import './Dashboard.css';
import { collection, getDocs, query, where, or } from 'firebase/firestore';
import L from 'leaflet';

const Dashboard = () => {
    const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
    const [isChatFeedModalOpen, setIsChatFeedModalOpen] = useState(false);
    const [groups, setGroups] = useState([]); // Groups to display
    const [visibleLocations, setVisibleLocations] = useState([]); // Locations visible on the map
    const [allowedGroupIds, setAllowedGroupIds] = useState([]); // IDs of groups the user can access
    const navigate = useNavigate();

    const [mapInstance, setMapInstance] = useState(null);

    useEffect(() => {
        const fetchUserGroups = async () => {
            if (!auth.currentUser) {
                setGroups([]);
                setAllowedGroupIds([]);
                return;
            }

            try {
                const groupsRef = collection(db, 'groups');
                const q = query(
                    groupsRef,
                    or(
                        where('members', 'array-contains', auth.currentUser.uid),
                        where('isPublic', '==', true)
                    )
                );
                const snapshot = await getDocs(q);
                const fetchedGroups = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setGroups(fetchedGroups);
                setAllowedGroupIds(fetchedGroups.map(group => group.id));
                console.log('Dashboard: Fetched Groups:', fetchedGroups); // Debugging Log
            } catch (error) {
                console.error('Error fetching user and public groups:', error);
            }
        };

        fetchUserGroups();
    }, []);

    const refreshGroups = useCallback(async () => {
        if (!auth.currentUser) {
            setGroups([]);
            setAllowedGroupIds([]);
            return;
        }

        try {
            const groupsRef = collection(db, 'groups');
            const q = query(
                groupsRef,
                or(
                    where('members', 'array-contains', auth.currentUser.uid),
                    where('isPublic', '==', true)
                )
            );
            const snapshot = await getDocs(q);
            const fetchedGroups = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setGroups(fetchedGroups);
            setAllowedGroupIds(fetchedGroups.map(group => group.id));
            console.log('Dashboard: Refreshed Groups:', fetchedGroups); // Debugging Log
        } catch (error) {
            console.error('Error fetching user and public groups:', error);
        }
    }, []);

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                navigate('/'); // Redirect to login after successful sign-out
            })
            .catch((error) => {
                console.error('Sign out error:', error);
            });
    };

    const openGroupModal = () => {
        setIsGroupModalOpen(true);
    };

    const closeGroupModal = () => {
        setIsGroupModalOpen(false);
    };

    const openChatFeedModal = () => {
        setIsChatFeedModalOpen(true);
    };

    const closeChatFeedModal = () => {
        setIsChatFeedModalOpen(false);
    };

    // Memoized function to handle visible locations change
    const handleVisibleLocationsChange = useCallback((visibleLocs) => {
        setVisibleLocations(visibleLocs);
        console.log('Dashboard: Visible Locations updated:', visibleLocs.map((loc) => loc.id));
    }, []);

    const centerMapOnLocation = useCallback((location) => {
        if (mapInstance) {
            mapInstance.flyTo([location.latitude, location.longitude], 18, {
                duration: 2,
            });

            // Remove existing tile layers
            mapInstance.eachLayer((layer) => {
                if (layer instanceof L.TileLayer) {
                    mapInstance.removeLayer(layer);
                }
            });

            // Add satellite layer
            new L.TileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
                attribution:
                    'Tiles &copy; Esri &mdash; Source: Esri, etc.',
            }).addTo(mapInstance);
        } else {
            console.error('Map instance is not available.');
        }

    }, [mapInstance]); // Include mapInstance in dependencies

    return (
        <div className="dashboard-container">
            <header className="dashboard-header">
                <h1 className="dashboard-title">mapChatter</h1>
                <nav className="dashboard-nav">
                    <div className="user-info">
                        {auth.currentUser && <span>{auth.currentUser.email}</span>}
                    </div>
                    <button onClick={openGroupModal} className="nav-button">Groups</button>
                    <button onClick={openChatFeedModal} className="nav-button">Chat</button>
                    <button onClick={handleLogout} className="nav-button logout-button">Logout</button>
                </nav>
            </header>

            <main className="dashboard-main">
                <div className="map-container">
                    <MapView
                        allowedGroupIds={allowedGroupIds}
                        onVisibleLocationsChange={handleVisibleLocationsChange}

                        setMapInstance={setMapInstance}
                        centerMapOnLocation={centerMapOnLocation}
                    />
                </div>
            </main>

            <ChatFeedModal
                isOpen={isChatFeedModalOpen}
                closeModal={closeChatFeedModal}
                visibleLocations={visibleLocations}
                centerMapOnLocation={centerMapOnLocation}

            />

            <GroupModal
                isOpen={isGroupModalOpen}
                closeModal={closeGroupModal}
                groups={groups}
                refreshGroups={refreshGroups}
            />
        </div>
    );
};

export default Dashboard;