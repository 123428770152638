import React, { useState, useEffect } from 'react';
import {
    Routes,
    Route,
    Navigate,

} from 'react-router-dom';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Dashboard from './pages/Dashboard';
import LandingPage from './pages/LandingPage'; // Import the LandingPage component
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './services/firebase';

const App = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Routes>
                <Route
                    path="/login"
                    element={!user ? <Login /> : <Navigate to="/" replace />}
                />
                <Route
                    path="/register"
                    element={!user ? <Register /> : <Navigate to="/" replace />}
                />
                <Route
                    path="/"
                    element={user ? <Dashboard /> : <LandingPage />} // Update to use LandingPage
                />
                {/* Add any other routes here */}
                <Route
                    path="*"
                    element={<Navigate to="/" replace />}
                />
            </Routes>
        </div>
    );
};

export default App;