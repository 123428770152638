import React, { useEffect, useState } from 'react';
import { db, auth } from 'services/firebase';
import {
    collection,
    query,
    where,
    onSnapshot,
    doc,
    updateDoc,
    getDocs,
    or,
} from 'firebase/firestore';
import Chat from '../Chat/Chat';
import './LocationDetailModal.css';
import { FaEdit } from 'react-icons/fa';
import Modal from 'react-modal';
import { useCallback } from 'react';
Modal.setAppElement('#root');
const LocationDetailModal = ({ location, closeModal, onLocationClick }) => {
    const [images, setImages] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState(location.name);
    const [description, setDescription] = useState(location.description);
    const [groupId, setGroupId] = useState(location.groupId);
    const [groups, setGroups] = useState([]);
    const currentUser = auth.currentUser;
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const handleImageClick = useCallback((imageUrl) => {
        setSelectedImage(imageUrl);
        setIsImageModalOpen(true);
    }, []);

    const closeImageModal = useCallback(() => {
        setIsImageModalOpen(false);
        setSelectedImage(null);
    }, []);

    const handleLocationClick = (location) => {
        if (onLocationClick) {
            onLocationClick(location);
        }
        if (closeModal) {
            closeModal();
        }
    };

    useEffect(() => {
        // This effect runs when the component mounts

        // Return a cleanup function that runs when the component unmounts
        return () => {
            setSelectedImage(null);
            setIsImageModalOpen(false);
        };
    }, []);


    useEffect(() => {
        const fetchGroups = async () => {
            if (!currentUser) return;
            try {
                const groupsRef = collection(db, 'groups');
                const q = query(
                    groupsRef,
                    or(
                        where('members', 'array-contains', currentUser.uid),
                        where('isPublic', '==', true)
                    )
                );
                const snapshot = await getDocs(q);
                const groupsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setGroups(groupsList);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchGroups();
    }, [currentUser]);

    useEffect(() => {
        // Fetch images related to the location
        const messagesRef = collection(db, 'locations', location.id, 'messages');
        const q = query(messagesRef, where('imageUrl', '!=', null));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const imgs = snapshot.docs.map(doc => doc.data().imageUrl);
            setImages(imgs);
        }, (error) => {
            console.error("Error fetching images:", error);
        });

        return () => unsubscribe();
    }, [location.id]);

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleSave = async () => {
        try {
            const locationRef = doc(db, 'locations', location.id);
            await updateDoc(locationRef, {
                name: name.trim(),
                description: description.trim(),
                groupId,
            });
            setIsEditing(false);
        } catch (error) {
            console.error("Error updating location:", error);
            alert('Failed to update location.');
        }
    };



    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <span className="close-button" onClick={closeModal}>&times;</span>
                {isEditing ? (


                    <div className="edit-form">
                        <h2>Edit Location</h2>
                        <div className="form-group">
                            <label htmlFor="name">Location Name:</label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder="Enter location name"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Description:</label>
                            <textarea
                                id="description"
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                placeholder="Enter description"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="group">Select Group:</label>
                            <select
                                id="group"
                                value={groupId}
                                onChange={e => setGroupId(e.target.value)}
                                required
                            >
                                <option value="">--Select Group--</option>
                                {groups.map(group => (
                                    <option key={group.id} value={group.id}>{group.name}</option>
                                ))}
                            </select>
                        </div>
                        <button onClick={handleEditToggle} className="create-group-button">Create New Group</button>
                        <button onClick={handleSave} className="submit-button">Update Location</button>
                    </div>
                ) : (
                    <>
                        <div className="location-header">
                            <div className="location-title-wrapper">
                                <h2 className="location-title">{location.name}</h2>
                                {groups.some(g => g.id === groupId && (g.members?.includes(currentUser?.uid) || g.isPublic)) && (
                                    <button onClick={handleEditToggle} className="edit-icon-button" aria-label="Edit location">
                                        <FaEdit />
                                    </button>
                                )}
                            </div>
                            <p className="location-description">{location.description}</p>
                            <p><strong>Group:</strong> {groups.find(g => g.id === groupId)?.name || 'N/A'}</p>
                            <div className="images-slider">
                                {images.length > 0 ? (
                                    images.map((url, idx) => (
                                        <img
                                            key={idx}
                                            src={url}
                                            alt={`Location ${idx + 1}`}
                                            className="slider-image"
                                            onClick={() => handleImageClick(url)}
                                        />
                                    ))
                                ) : (
                                    <p>No images available.</p>
                                )}
                            </div>
                            {selectedImage && (
                                <Modal
                                    isOpen={isImageModalOpen}
                                    onRequestClose={closeImageModal}
                                    contentLabel="Image Preview"
                                    className="image-modal"
                                    overlayClassName="image-modal-overlay"
                                    shouldCloseOnOverlayClick={true}
                                    shouldCloseOnEsc={true}
                                >
                                    {selectedImage && (
                                        <div className="image-modal-content">
                                            <img src={selectedImage} alt="Full View" className="full-image" />
                                            <button onClick={closeImageModal} className="close-modal-button">
                                                &times;
                                            </button>
                                        </div>
                                    )}
                                </Modal>
                            )}
                        </div>
                        <div className="chat-section">
                            <Chat
                                location={location}
                                onLocationClick={handleLocationClick}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default LocationDetailModal;