import React, { useState, useRef, useEffect } from 'react';
import './CustomDropdown.css';

const CustomDropdown = ({ options, selectedValue, onChange, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const wrapperRef = useRef(null);

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = event => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleOptionClick = value => {
        onChange(value);
        setIsOpen(false);
    };

    const selectedOption = options.find(option => option.value === selectedValue);

    return (
        <div className="custom-dropdown" ref={wrapperRef}>
            <div
                className="custom-dropdown-selected"
                onClick={() => setIsOpen(prev => !prev)}
            >
                {selectedOption ? selectedOption.label : placeholder}
                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}></span>
            </div>
            {isOpen && (
                <ul className="custom-dropdown-list">
                    {options.map(option => (
                        <li
                            key={option.value}
                            className="custom-dropdown-item"
                            onClick={() => handleOptionClick(option.value)}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomDropdown;