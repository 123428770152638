import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    LayersControl,
    useMapEvents,
    useMap,
} from 'react-leaflet';
import { collection, onSnapshot, query, where, getDocs, documentId } from 'firebase/firestore';
import { db } from 'services/firebase';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import AddLocationModal from './AddLocationModal';
import LocationDetailModal from './LocationDetailModal';
import './MapView.css';

import { useDebounce } from 'use-debounce';
import HelpModal from '../Help/HelpModal';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import { FaLocationArrow } from 'react-icons/fa';
import { FaQuestionCircle } from 'react-icons/fa';

// Fix Leaflet's default icon issue with Webpack
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Define custom icons
const defaultIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

const highlightedIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

// Helper function to split array into chunks
const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};





// BoundsTracker Component to monitor visible locations within map bounds
const BoundsTracker = ({ locations, onVisibleLocationsChange }) => {
    const map = useMap();

    useEffect(() => {
        const updateVisibleLocations = () => {
            const bounds = map.getBounds();
            const visible = locations.filter((loc) =>
                bounds.contains([loc.latitude, loc.longitude])
            );

            console.log(
                'MapView: Updating visible locations based on bounds:',
                visible.map((loc) => loc.id)
            );

            onVisibleLocationsChange(visible);
        };

        map.on('moveend zoomend load', updateVisibleLocations);
        updateVisibleLocations(); // Initial call

        return () => {
            map.off('moveend zoomend load', updateVisibleLocations);
        };
    }, [map, locations, onVisibleLocationsChange]);

    return null;
};

// Component to adjust map view to show all filtered locations
const AdjustMapBounds = ({ locations }) => {
    const map = useMap();

    useEffect(() => {
        if (locations.length === 0) return;

        const bounds = L.latLngBounds(locations.map((loc) => [loc.latitude, loc.longitude]));
        map.fitBounds(bounds, { padding: [50, 50] });
    }, [locations, map]);

    return null;
};

// FlyToCurrentLocation Component
const FlyToCurrentLocation = ({ currentPosition }) => {
    const map = useMap();

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();


        if (currentPosition) {
            console.log('FlyToCurrentLocation: Flying to current position:', currentPosition);
            map.flyTo(currentPosition, 14);
        } else {
            console.log('FlyToCurrentLocation: Current position not available');
            alert('Current position is not available.');
        }
    };

    return (
        <div className="leaflet-control leaflet-bar fly-to-current-location-container">
            <button
                className="fly-to-current-location"
                onClick={handleClick}
                title="Fly to current location"
                aria-label="Fly to current location"
            >
                <FaLocationArrow />
            </button>
        </div>
    );
};



// This component will handle map interactions
const MapController = ({ onMapInstanceReady }) => {
    const map = useMap();

    useEffect(() => {
        if (onMapInstanceReady) {
            onMapInstanceReady(map);
        }
    }, [map, onMapInstanceReady]);

    return null;
};

const MapView = ({ onVisibleLocationsChange, allowedGroupIds, centerMapOnLocation, setMapInstance }) => {
    // State declarations
    const [locations, setLocations] = useState([]);
    const [currentPosition, setCurrentPosition] = useState(null);
    const [hasLocation, setHasLocation] = useState(false);
    const [locationError, setLocationError] = useState(null);
    const [loadingLocation, setLoadingLocation] = useState(true);
    const [isAddLocationModalOpen, setIsAddLocationModalOpen] = useState(false);
    const [selectedLatLng, setSelectedLatLng] = useState(null);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null); // Group filter state
    const [searchTerm, setSearchTerm] = useState('');
    const [groupDetails, setGroupDetails] = useState([]);
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
    const [showLocationDetail, setShowLocationDetail] = useState(false);
    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
    const mapRef = useRef();

    // Memoize handleMapClick to stabilize its reference
    const handleMapClick = useCallback((e) => {
        setSelectedLatLng(e.latlng);
        setIsAddLocationModalOpen(true);
    }, []);

    // Memoize handleMarkerClick to stabilize its reference
    const handleMarkerClick = useCallback((location) => {
        setSelectedLocation(location);
        setIsDetailModalOpen(true);
    }, []);

    // Function to open the LocationDetail modal
    const handleOpenLocationDetail = (location) => {
        setSelectedLocation(location);
        setShowLocationDetail(true);
    };

    // Function to close the LocationDetail modal
    const handleCloseLocationDetail = () => {
        setShowLocationDetail(false);
        setSelectedLocation(null);
    };

    const MapEventHandler = () => {
        useMapEvents({
            click: (e) => {
                if (e.originalEvent.target.closest('.leaflet-control')) {
                    // Click was on a map control element; ignore it
                    return;
                }
                handleMapClick(e);
            },
        });
        return null;
    };

    // Fetch locations based on allowedGroupIds with batching
    useEffect(() => {
        let unsubscribeFunctions = [];

        const fetchLocations = () => {
            if (allowedGroupIds.length === 0) {
                setLocations([]);
                return;
            }

            try {
                // Split allowedGroupIds into batches of 10
                const batches = chunkArray(allowedGroupIds, 10);

                batches.forEach((batch) => {
                    const q = query(
                        collection(db, 'locations'),
                        where('groupId', 'in', batch)
                    );

                    const unsubscribe = onSnapshot(q, (snapshot) => {
                        const fetchedLocations = snapshot.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        }));

                        setLocations((prevLocations) => {
                            // Remove previous locations from this batch's groups
                            const locationsWithoutBatchGroups = prevLocations.filter(
                                (loc) => !batch.includes(loc.groupId)
                            );
                            return [...locationsWithoutBatchGroups, ...fetchedLocations];
                        });
                    });
                    unsubscribeFunctions.push(unsubscribe);
                });
            } catch (error) {
                console.error('MapView: Error fetching locations:', error);
            }
        };

        console.log('MapView: allowedGroupIds changed:', allowedGroupIds);
        fetchLocations();

        return () => {
            // Cleanup all subscriptions
            unsubscribeFunctions.forEach((unsub) => unsub());
        };
    }, [allowedGroupIds]);

    // Fetch group details based on allowedGroupIds with batching
    useEffect(() => {
        const fetchGroupDetails = async () => {
            if (allowedGroupIds.length === 0) {
                setGroupDetails([]);
                return;
            }

            try {
                const batches = chunkArray(allowedGroupIds, 10);
                const groupsList = [];

                for (const batch of batches) {
                    const q = query(
                        collection(db, 'groups'),
                        where(documentId(), 'in', batch)
                    );
                    const snapshot = await getDocs(q);
                    const fetchedGroups = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    groupsList.push(...fetchedGroups);
                }

                setGroupDetails(groupsList);
                console.log('MapView: Fetched Group Details:', groupsList);
            } catch (error) {
                console.error('MapView: Error fetching group details:', error);
            }
        };

        fetchGroupDetails();
    }, [allowedGroupIds]);

    useEffect(() => {
        // Get user's current position
        if (!navigator.geolocation) {
            setLocationError('Geolocation is not supported by your browser');
            setLoadingLocation(false);
            console.error('MapView: Geolocation not supported.');
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = [position.coords.latitude, position.coords.longitude];
                    setCurrentPosition(pos);
                    setHasLocation(true);
                    setLoadingLocation(false);
                    console.log('MapView: User position obtained:', pos);
                },
                (error) => {
                    setLocationError(error.message);
                    setLoadingLocation(false);
                    console.error('MapView: Error obtaining location:', error);
                }
            );
        }
    }, []);

    // Helper function to determine if a location matches the search term
    const isMatch = useCallback(
        (location, term = searchTerm) => {
            const lowerCaseTerm = term.toLowerCase();
            return (
                location.name.toLowerCase().includes(lowerCaseTerm) ||
                (location.description &&
                    location.description.toLowerCase().includes(lowerCaseTerm))
            );
        },
        [searchTerm]
    );

    // Memoize filtered locations based on search term and selected group
    const filteredLocations = useMemo(() => {
        return locations.filter((location) => {
            // Filter by selected group if any
            if (selectedGroup && location.groupId !== selectedGroup) {
                return false;
            }
            // Filter by debounced search term
            if (debouncedSearchTerm && !isMatch(location, debouncedSearchTerm)) {
                return false;
            }
            return true;
        });
    }, [locations, selectedGroup, debouncedSearchTerm, isMatch]);

    // Use useEffect to call onVisibleLocationsChange whenever filteredLocations change
    // and when map bounds change via BoundsTracker
    const [visibleLocations, setVisibleLocations] = useState([]);

    // Update onVisibleLocationsChange when visibleLocations change
    useEffect(() => {
        onVisibleLocationsChange(visibleLocations);
        console.log(
            'MapView: Visible Locations updated:',
            visibleLocations.map((loc) => loc.id)
        );
    }, [visibleLocations, onVisibleLocationsChange]);

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Prepare options for the custom dropdown
    const groupOptions = useMemo(() => {
        return [
            { value: '', label: 'All Groups' },
            ...groupDetails.map((group) => ({
                value: group.id,
                label: group.name || 'Unnamed Group',
            })),
        ];
    }, [groupDetails]);

    const handleGroupFilterChange = (value) => {
        setSelectedGroup(value);
    };

    return (
        <div className="map-view-container">
            <MapContainer
                center={currentPosition || [37.68803, -77.2605]}
                zoom={13}
                scrollWheelZoom={true}
                style={{ height: '100vh', width: '100%' }}
                ref={mapRef}
            >
                <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="OpenStreetMap">
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution="&copy; OpenStreetMap contributors"
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Satellite">
                        <TileLayer
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            attribution="Tiles &copy; Esri &mdash; Source: Esri, etc."
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>
                <MapEventHandler onMapClick={handleMapClick} />
                {currentPosition && hasLocation && (
                    <Marker position={currentPosition} icon={highlightedIcon}>
                        <Popup>You are here.</Popup>
                    </Marker>
                )}
                <BoundsTracker
                    locations={filteredLocations}
                    onVisibleLocationsChange={setVisibleLocations}
                />
                {filteredLocations.length > 0 && (
                    <AdjustMapBounds locations={filteredLocations} />
                )}
                {filteredLocations.map((location) => {
                    const match = searchTerm ? isMatch(location) : false;
                    return (
                        <Marker
                            key={location.id}
                            position={[location.latitude, location.longitude]}
                            icon={match ? highlightedIcon : defaultIcon}
                            eventHandlers={{
                                click: () => handleMarkerClick(location),
                            }}
                        />
                    );
                })}
                <MapController onMapInstanceReady={setMapInstance} />
                <FlyToCurrentLocation currentPosition={currentPosition} />
                <div className="leaflet-control leaflet-bar help-button-container">
                    <button
                        className="help-button"
                        onClick={() => setIsHelpModalOpen(true)}
                        aria-label="Open Help"
                    >
                        <FaQuestionCircle />
                    </button>
                </div>
                {isDetailModalOpen && selectedLocation && (
                    <LocationDetailModal
                        location={selectedLocation}
                        closeModal={() => setIsDetailModalOpen(false)}
                        onLocationClick={centerMapOnLocation} // Pass the function down
                    />
                )}
            </MapContainer>

            {/* Move controls outside of MapContainer and adjust their position */}
            <div className="map-controls">
                <input
                    type="text"
                    className="search-input"
                    placeholder="Search locations..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    aria-label="Search Locations"
                />
                <CustomDropdown
                    options={groupOptions}
                    selectedValue={selectedGroup}
                    onChange={handleGroupFilterChange}
                    placeholder="All Groups"
                />

            </div>


            {/* Include other modals and components */}
            {isAddLocationModalOpen && (
                <AddLocationModal
                    closeModal={() => setIsAddLocationModalOpen(false)}
                    latlng={selectedLatLng}
                    userGroups={allowedGroupIds}
                />
            )}

            {isDetailModalOpen && selectedLocation && (
                <LocationDetailModal
                    location={selectedLocation}
                    closeModal={() => setIsDetailModalOpen(false)}
                    onLocationClick={centerMapOnLocation}
                />
            )}

            {/* Include the HelpModal */}
            {isHelpModalOpen && (
                <HelpModal closeModal={() => setIsHelpModalOpen(false)} />
            )}

            {/* Display location error if any */}
            {locationError && (
                <div className="error-message">{locationError}</div>
            )}

            {/* Display loading message if still loading */}
            {loadingLocation && (
                <div className="loading-message">Loading current location...</div>
            )}
        </div>
    );
};

export default MapView;