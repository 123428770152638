import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import { db, auth } from 'services/firebase';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import './ChatFeedModal.css';
import { addDoc, serverTimestamp } from 'firebase/firestore';

const ChatFeedModal = ({ isOpen, closeModal, visibleLocations = [], centerMapOnLocation }) => {
    const [messagesMap, setMessagesMap] = useState({});
    const [newMessage, setNewMessage] = useState('');
    const [selectedLocationId, setSelectedLocationId] = useState('');
    const [replyMessageMap, setReplyMessageMap] = useState({});
    const [replyingToId, setReplyingToId] = useState(null);
    const currentUser = auth.currentUser;
    const safeVisibleLocations = useMemo(() => {
        return Array.isArray(visibleLocations) ? visibleLocations : [];
    }, [visibleLocations]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);

    const unsubscribeMapRef = useRef({});

    useEffect(() => {
        console.log('ChatFeedModal mounted');
        return () => {
            console.log('ChatFeedModal unmounted');
            Object.values(unsubscribeMapRef.current).forEach(unsub => unsub());
            unsubscribeMapRef.current = {};
            setMessagesMap({});
            setSelectedImage(null);
            setIsImageModalOpen(false);
        };
    }, []);

    useEffect(() => {
        if (safeVisibleLocations.length > 0) {
            setSelectedLocationId(prevId => {
                if (prevId && safeVisibleLocations.some(loc => loc.id === prevId)) {
                    return prevId;
                } else {
                    return safeVisibleLocations[0].id;
                }
            });
        } else {
            setSelectedLocationId('');
        }
    }, [safeVisibleLocations]);

    useEffect(() => {
        const unsubscribeMap = unsubscribeMapRef.current;
        const currentVisibleLocationIds = safeVisibleLocations.map(loc => loc.id);

        console.log("ChatFeedModal: Visible Location IDs (Effect):", currentVisibleLocationIds);

        Object.keys(unsubscribeMap).forEach(locationId => {
            if (!currentVisibleLocationIds.includes(locationId)) {
                console.log(`ChatFeedModal: Unsubscribing from location: ${locationId}`);
                unsubscribeMap[locationId]();
                delete unsubscribeMap[locationId];
                setMessagesMap(prevMap => {
                    const newMap = { ...prevMap };
                    delete newMap[locationId];
                    return newMap;
                });
            }
        });

        currentVisibleLocationIds.forEach(locationId => {
            if (!unsubscribeMap[locationId]) {
                const location = safeVisibleLocations.find(loc => loc.id === locationId);

                console.log(`ChatFeedModal: Subscribing to location: ${locationId}`);
                const messagesRef = collection(db, 'locations', locationId, 'messages');
                const q = query(messagesRef, orderBy('timestamp', 'desc'));

                const unsubscribe = onSnapshot(
                    q,
                    snapshot => {
                        const locationMessages = snapshot.docs.map(doc => ({
                            id: doc.id,
                            ...doc.data(),
                            locationName: location.name,
                            locationId: locationId,
                        }));
                        console.log(`ChatFeedModal: Fetched messages for location ${locationId}:`, locationMessages);
                        setMessagesMap(prevMap => ({
                            ...prevMap,
                            [locationId]: locationMessages,
                        }));
                    },
                    error => {
                        console.error(`ChatFeedModal: Error fetching messages for location ${locationId}:`, error);
                    }
                );

                unsubscribeMap[locationId] = unsubscribe;
            }
        });

        return () => {
            Object.values(unsubscribeMapRef.current).forEach(unsub => unsub());
            unsubscribeMapRef.current = {};
            setMessagesMap({});
        };
    }, [safeVisibleLocations]);

    const visibleLocationIds = useMemo(
        () => safeVisibleLocations.map(loc => loc.id),
        [safeVisibleLocations]
    );

    const combinedMessages = useMemo(() => {
        if (messagesMap && visibleLocationIds.length > 0) {
            const combined = Object.entries(messagesMap)
                .filter(([locationId]) => visibleLocationIds.includes(locationId))
                .flatMap(([, messages]) => messages);
            console.log("ChatFeedModal: Combined Messages:", combined);
            return combined;
        }
        return [];
    }, [messagesMap, visibleLocationIds]);

    console.log("ChatFeedModal: Combined Messages for rendering:", combinedMessages);

    const handleSendMessage = useCallback(
        async e => {
            e.preventDefault();
            if (!selectedLocationId) {
                alert('Please select a location to send a message.');
                return;
            }
            if (!newMessage.trim()) return;

            try {
                await addDoc(collection(db, 'locations', selectedLocationId, 'messages'), {
                    text: newMessage.trim(),
                    user: currentUser ? currentUser.email : 'Anonymous',
                    timestamp: serverTimestamp(),
                });
                setNewMessage('');
            } catch (error) {
                console.error('ChatFeedModal: Error sending message:', error);
                alert('Failed to send message.');
            }
        },
        [newMessage, selectedLocationId, currentUser]
    );

    const handleReply = useCallback(
        async (parentId) => {
            const replyText = replyMessageMap[parentId]?.trim();
            if (!replyText) return;

            const message = combinedMessages.find(msg => msg.id === parentId);
            if (!message) {
                alert('Original message not found.');
                return;
            }

            try {
                await addDoc(collection(db, 'locations', message.locationId, 'messages'), {
                    text: replyText,
                    user: currentUser ? currentUser.email : 'Anonymous',
                    timestamp: serverTimestamp(),
                    parentId: parentId,
                });
                setReplyMessageMap(prev => ({ ...prev, [parentId]: '' }));
                setReplyingToId(null);
            } catch (error) {
                console.error('ChatFeedModal: Error sending reply:', error);
                alert('Failed to send reply.');
            }
        },
        [replyMessageMap, combinedMessages, currentUser]
    );

    // Helper function to get display name
    const getDisplayName = (email) => {
        if (!email) return 'Anonymous';
        const index = email.indexOf('@');
        return index !== -1 ? email.substring(0, index) : email;
    };

    const renderReplies = (parentId, level = 1) => {
        const replies = combinedMessages
            ? combinedMessages.filter(msg => msg.parentId === parentId)
            : [];
        return replies
            .sort((a, b) => (a.timestamp?.seconds || 0) - (b.timestamp?.seconds || 0))
            .map(reply => (
                <div key={reply.id} className="reply-item" style={{ marginLeft: level * 20 }}>
                    <div className="chat-item">
                        <div className="chat-header">
                            <div className="chat-user-info">
                                <strong>{getDisplayName(reply.user)}</strong>
                                <span className="chat-timestamp">
                                    {reply.timestamp?.toDate().toLocaleString()}
                                </span>
                            </div>
                        </div>
                        <div className="chat-content">
                            <p>{reply.text}</p>
                        </div>
                        <button onClick={() => setReplyingToId(reply.id)} className="reply-button">
                            Reply
                        </button>
                        {replyingToId === reply.id && (
                            <form
                                onSubmit={e => {
                                    e.preventDefault();
                                    handleReply(reply.id);
                                }}
                                className="reply-form"
                            >
                                <input
                                    type="text"
                                    value={replyMessageMap[reply.id] || ''}
                                    onChange={e =>
                                        setReplyMessageMap(prev => ({
                                            ...prev,
                                            [reply.id]: e.target.value,
                                        }))
                                    }
                                    placeholder="Type a reply..."
                                    className="reply-input"
                                />
                                <button type="submit" className="send-reply-button">
                                    Send
                                </button>
                            </form>
                        )}
                        {/* Render nested replies */}
                        {renderReplies(reply.id, level + 1)}
                    </div>
                </div>
            ));
    };

    const handleLocationClick = useCallback((locationId) => {
        const location = visibleLocations.find((loc) => loc.id === locationId);
        if (location && centerMapOnLocation) {
            centerMapOnLocation(location);
            closeModal();
        } else {
            console.error('Location or centerMapOnLocation function is not available.');
        }
    }, [visibleLocations, centerMapOnLocation, closeModal]);

    const handleImageClick = useCallback((imageUrl) => {
        setSelectedImage(imageUrl);
        setIsImageModalOpen(true);
    }, []);

    const closeImageModal = useCallback(() => {
        setIsImageModalOpen(false);
        setSelectedImage(null);
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Chat Feed"
            className="chat-feed-modal"
            overlayClassName="modal-overlay"
        >
            <button onClick={closeModal} className="close-button">
                &times;
            </button>

            <div className="chat-feed-container">
                {combinedMessages.length > 0 ? (
                    combinedMessages
                        .filter(msg => !msg.parentId)
                        .sort((a, b) => (b.timestamp?.seconds || 0) - (a.timestamp?.seconds || 0))
                        .map(message => (
                            <div key={message.id} className="chat-item">
                                <div className="chat-header">
                                    <div className="chat-user-info">
                                        {/* Add location name as a clickable link */}
                                        <strong
                                            className="location-link"
                                            onClick={() => handleLocationClick(message.locationId)}
                                        >
                                            {visibleLocations.find(loc => loc.id === message.locationId)?.name || 'Unknown Location'}
                                        </strong>
                                        <span className="chat-timestamp">
                                            {message.timestamp?.toDate().toLocaleString()}
                                        </span>
                                    </div>
                                    <div className="poster-name">
                                        {getDisplayName(message.user)}
                                    </div>
                                </div>
                                <div className="chat-content">
                                    <p>{message.text}</p>
                                    {message.imageUrl && (
                                        <img
                                            src={message.imageUrl}
                                            alt="Message Attachment"
                                            className="chat-image"
                                            onClick={() => handleImageClick(message.imageUrl)}
                                        />
                                    )}
                                </div>
                                <button onClick={() => setReplyingToId(message.id)} className="reply-button">
                                    Reply
                                </button>
                                {replyingToId === message.id && (
                                    <form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            handleReply(message.id);
                                        }}
                                        className="reply-form"
                                    >
                                        <input
                                            type="text"
                                            value={replyMessageMap[message.id] || ''}
                                            onChange={e =>
                                                setReplyMessageMap(prev => ({
                                                    ...prev,
                                                    [message.id]: e.target.value,
                                                }))
                                            }
                                            placeholder="Type a reply..."
                                            className="reply-input"
                                        />
                                        <button type="submit" className="send-reply-button">
                                            Send
                                        </button>
                                    </form>
                                )}
                                {/* Render replies */}
                                {renderReplies(message.id)}
                            </div>
                        ))
                ) : (
                    <p>No messages available for visible locations.</p>
                )}
            </div>
            <form onSubmit={handleSendMessage} className="chat-feed-form">
                <select
                    value={selectedLocationId}
                    onChange={e => setSelectedLocationId(e.target.value)}
                    className="location-select"
                >
                    <option value="" disabled>
                        Select Location
                    </option>
                    {safeVisibleLocations.length > 0 ? (
                        safeVisibleLocations.map(location => (
                            <option key={location.id} value={location.id}>
                                {location.name}
                            </option>
                        ))
                    ) : (
                        <option disabled>No locations available</option>
                    )}
                </select>
                <input
                    type="text"
                    value={newMessage}
                    onChange={e => setNewMessage(e.target.value)}
                    placeholder="Type a message..."
                    className="chat-feed-input"
                />
                <button type="submit" className="send-button">
                    Send
                </button>
            </form>
            <Modal
                isOpen={isImageModalOpen}
                onRequestClose={closeImageModal}
                contentLabel="Image Preview"
                className="image-modal"
                overlayClassName="image-modal-overlay"
            >
                {selectedImage && (
                    <div className="image-modal-content">
                        <img src={selectedImage} alt="Full View" className="full-image" />
                        <button onClick={closeImageModal} className="close-modal-button">
                            &times;
                        </button>
                    </div>
                )}
            </Modal>
        </Modal>
    );
};

export default ChatFeedModal;